<template>
  <div :class="['oa-form', { 'disabled': disabled }]">
    <el-card content-position="left" class="form-header">
      <el-page-header @back="goBack" content="主办方" size="small"></el-page-header>
      <div class="button-box">
        <span v-if="disabled">
          <el-button round type="primary" size="small" @click="disabled = false">
            <font-awesome-icon fas icon="edit"></font-awesome-icon>&nbsp;编辑
          </el-button>
        </span>
        <span v-else>
          <el-button round type="primary" size="small" @click="submit">
            <font-awesome-icon fas icon="save"></font-awesome-icon>&nbsp;保存
          </el-button>
          <el-button round type="warning" size="small" @click="disabled = true">
            <font-awesome-icon fas icon="reply"></font-awesome-icon>&nbsp;取消
          </el-button>
        </span>
      </div>
    </el-card>
    <div class="form-content">
      <el-form status-icon :disabled="disabled" :model="entity" :rules="validationRule" class="form" ref="form"
      label-width="120px">
        <el-card content-position="left">
          <div slot="header">
            <span class="card-header-label">基本信息</span>
          </div>
          <div class="row">
            <el-form-item prop="Name" label="名称">
              <el-input v-model="entity.Name" size="mini" placeholder="请输入名称"></el-input>
            </el-form-item>
            <el-form-item prop="CompanyName" label="公司名称">
              <el-input v-model="entity.CompanyName" size="mini" placeholder="请输入公司名称"></el-input>
            </el-form-item>
            <el-form-item prop="ECompanyName" label="公司英文名称">
              <el-input v-model="entity.ECompanyName" size="mini" placeholder="请输入公司英文名称"></el-input>
            </el-form-item>
          </div>
          <div class="row">
            <el-form-item prop="Address" label="公司地址" class="address-item">
              <el-input v-model="entity.Address" size="mini" placeholder="请输入公司地址"></el-input>
            </el-form-item>
          </div>
          <div class="row">
            <el-form-item label="描述" prop="Description" class="address-item">
              <el-input show-word-limit v-model="entity.Description" :rows="5" type="textarea" maxlength="300"
                placeholder="请输入描述">
              </el-input>
            </el-form-item>
          </div>
        </el-card>
      </el-form>
    </div>
  </div>
</template>

<script>
import API from '../../apis/ticket-api'
import { ORGANIZER, ORGANIZER_FORM } from '../../router/base-router'

export default {
  name: ORGANIZER_FORM.name,
  data () {
    return {
      disabled: true, // 允许编辑
      loading: false, // 加载中
      isAdd: false, // 添加模式
      entity: {}, // 实体
      validationRule: {
        Name: [{ required: true, message: '请输入主办方名称', trigger: 'blur' }]
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => vm.init())
  },
  methods: {
    init () {
      if (!this.loading) {
        this.loading = true
        this.isAdd = this.$route.params.isAdd
        this.entity = {
          ...this.$route.params
        }
        if (this.isAdd) {
          this.disabled = false
        }
        this.loading = false
        this.$nextTick(() => { this.$refs.form.clearValidate() })
      }
    },
    get () {
      this.axios.get(`${API.ORGANIZER.URL}/${this.entity.Id}`)
        .then(response => {
          this.entity = response
        })
    },
    submit () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.isAdd) {
            this.add()
          } else {
            this.update()
          }
        }
      })
    },
    add () {
      this.axios.post(API.ORGANIZER.URL, this.entity)
        .then(response => {
          if (response.Status) {
            this.$router.push(ORGANIZER)
          }
        })
    },
    update () {
      this.axios.put(API.ORGANIZER.URL, this.entity)
        .then(response => {
          if (response.Status) {
            this.disabled = true
          }
        })
    },
    goBack () {
      this.$router.push(ORGANIZER)
    }
  },
  created () {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.form {

  .address-item {
    flex: 1;
    max-width: 1000px;

    .el-input,
    input {
      width: 100%;
    }
  }

  .remark-item {
    flex: 1;
    max-width: 1000px;
  }
}
</style>
